<template>
<div class="h-100" style="overflow-y: auto">
  <div class="container" >
    <div class="mt-5 row">
      <div class="col-9" >
        <!-- <div style="font-family: Rubik;font-style: normal;font-weight: bold;font-size: 24px;letter-spacing: -0.5px;color: #545454;">My Items ({{ cartCount }})</div> -->
        <div style="border-radius:10px;">
          <div v-if="games.length <= 0">Looks like you don't have any items in your cart!</div>
            <div style="overflow-y: hidden; border-top-right-radius: 10px; border-top-left-radius: 10px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);">
            <table class="table guard-font table-fixed" style="border-top-right-radius: 10px; border-top-left-radius: 10px; margin-bottom: 0;">
              <template v-for="game in sortedGames">
                <thead  v-bind:key="'cart-' + game.id">
                  <tr class="thead" style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #333637;">
                    <th scope="col" style="padding:16px;">{{game.name}} ({{game.items.length}})</th>
                    <th scope="col" style="padding:16px;">
                      <div class="d-flex" style="cursor: pointer;" @click="toggleSort('name')">
                        <div style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #333637;">Item Name</div>
                        <!-- <div class="ml-2 d-flex flex-column">
                          <font-awesome-icon icon="caret-up" style="margin-top:-3px;" :style="{ 'color': sort == 'name-asc' ? '#ED6B33' : '#B0B0B0' }" />
                          <font-awesome-icon icon="caret-down" style="margin-top:-3px;" :style="{ 'color': sort == 'name-desc' ? '#ED6B33' : '#B0B0B0' }"/>
                        </div> -->
                      </div>
                    </th>
                    <th scope="col" style="padding:16px;">Item Info</th>
                    <th scope="col" style="padding:16px;">
                      <div class="d-flex" style="cursor: pointer;" @click="toggleSort('quantity')" >
                        <div style="margin-left:10px; font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #333637;">Quantity</div>
                        <!-- <div class="ml-2 d-flex flex-column">
                          <font-awesome-icon icon="caret-up" style="margin-top:-3px;" :style="{ 'color': sort == 'quantity-asc' ? '#ED6B33' : '#B0B0B0' }" />
                          <font-awesome-icon icon="caret-down" style="margin-top:-3px;" :style="{ 'color': sort == 'quantity-desc' ? '#ED6B33' : '#B0B0B0' }" />
                        </div> -->
                      </div>
                    </th>
                    <th scope="col" style="padding:16px;">
                      <div class="d-flex" style="cursor: pointer;" @click="toggleSort('price')">
                        <div style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #333637;">Price</div>
                        <!-- <div class="ml-2 d-flex flex-column">
                          <font-awesome-icon icon="caret-up" style="margin-top:-3px;" :style="{ 'color': sort == 'price-asc' ? '#ED6B33' : '#B0B0B0' }" />
                          <font-awesome-icon icon="caret-down" style="margin-top:-3px;" :style="{ 'color': sort == 'price-desc' ? '#ED6B33' : '#B0B0B0' }" />
                        </div> -->
                      </div>
                    </th>
                    <th scope="col" style="padding:16px;">Remove</th>
                  </tr>
                </thead>
                <tbody v-bind:key="game.id">
                  <tr v-for="item of game.items" v-bind:key="item.id">
                    <td class="ib item-image" style="width: auto">
                      <div>
                        <img
                          :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + item.image + '/256fx256f'"
                          alt=""
                          style="width: auto; height: 70px; margin-left:15px;"
                          />
                      </div>
                    </td>
                    <td class="ib item-name" style="vertical-align: middle;">
                      <div>
                        <div class="item-title">{{item.name}}</div>
                        <div class="item-status">
                            <div v-if="item.stattrak" class="item-stattrak">StatTrak™</div> 
                            <!-- <span v-if="item.stattrak && item.wear"> | </span> -->
                            <div v-if="item.wear" class="item-wear"> {{item.wear}} </div>
                        </div>
                      </div>
                    </td>
                    <td class="ib item-desc" style="vertical-align: middle;">
                      <div v-if="item.stickers">
                        <span v-for="(sticker, i ) in item.stickers" v-bind:key="i">
                          <img v-tooltip="sticker.name + parseStickerScrape(sticker.wear)" :src="sticker.image" style="max-height:24px" alt="Sticker">
                          <font-awesome-icon v-if="sticker.wear" icon="exclamation-circle" class="sticker-wear-alert-icon-cart" style="color: #ED6B33; max-height: 12px;"/>
                        </span>
                      </div>
                      <div v-if="item.float && item.float.toFixed" style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px; letter-spacing: 0.2px;color: #484848;">Float Value: <span>{{item.float.toFixed(4)}}</span></div>
                      <div v-else style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px; letter-spacing: 0.2px;color: #484848;">Float Value: <span>Not Available</span></div>
                      <div style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px; letter-spacing: 0.2px;color: #484848;">Pattern Index: <span>{{item.patternIndex}}</span></div>
                    </td>
                    <td class="ib" style="vertical-align: middle;">                      
                      <div class="input-group mt-1" style="width:77px; " :class="{'disabled': true || item.type != 'COMMODITY'}" >
                        <div class="input-group-prepend" @click="quantityDecrease(item)">
                          <span
                            class="input-group-text"
                            style="border-top-left-radius: 25px;border-bottom-left-radius: 25px; background-color:white; font-weight: bold; padding-right:0px; cursor: pointer;border-right:0px; border:01px solid #E8E8E8"
                          >-</span>
                        </div>

                        <input
                          type="text"
                          class="form-control-plaintext text-center qtyinput"
                          v-bind:value="item.type == 'COMMODITY' ? $store.getters['cart/qtyOf'](item) : 1"
                          v-on:input="setQuantity"
                          :disabled="true || item.type != 'COMMODITY'"
                        />

                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            style="border-top-right-radius: 25px;border-bottom-right-radius: 25px; background-color:white; font-weight:bold; padding-left: 0px; cursor: pointer; border-left:0; border:1px solid #E8E8E8"
                            @click="quantityIncrease(item)"
                          >+</span>
                        </div>
                      </div>
                    </td>
                    <td class="ib" style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 14px;color: #96C77E;vertical-align: middle;"> ${{((item.type == 'COMMODITY' ? numeral($store.getters["cart/costOf"](item)).value() : numeral(item.listPrice).value()).toFixed(2))}}  <font-awesome-icon v-if="item.priceChanged" v-tooltip="'Price Changed!'" icon="exclamation-triangle" style="color: orange;"/></td>
                    <td class="ib item-remove" style="vertical-align: middle;padding:0;margin-right:39px">
                      <a href="#0" @click="remove(item)"><font-awesome-icon icon="trash" /></a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      </div>
      <div class="col-3">
        <!-- <div style="font-family: Rubik;font-style: normal;font-weight: bold;font-size: 24px;letter-spacing: -0.5px;color: #545454;">Cart</div> -->
        <div class="card" style="border-radius:10px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);">
          <div class="card-header" style="background: rgba(29, 32, 33, 0.9); font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #FFFFFF;border-top-right-radius:10px;border-top-left-radius:10px;">
            {{cartCount}} Items in cart
          </div>
          <div class="card-body w-100">
            <div>
              <div style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 18px;color: #545454;">Order Summary</div>
              <div class="d-flex justify-content-between mt-1">
                <div class=" roboto-10">Cart Total</div>
                <div class=" roboto-10">${{ toFixed(cartTotal, 2)}}</div>
              </div>

              <div v-if="regionTax > 0">
                <div class="d-flex justify-content-between mt-1" v-tooltip="taxTooltip">
                  <div class=" roboto-10">Sales Tax
                    <div class="badge my-auto ml-2">
                      <img src="@/assets/info.svg" alt />
                    </div>
                  </div>
                  <div class=" roboto-10">${{toFixed(regionTax, 2)}}</div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <div class=" roboto-10">Sub Total</div>
                  <div class=" roboto-10 border-top">${{toFixed(subTotal, 2) }}</div>
                </div>
              </div>
              <div v-if="balanceApplied > 0" class="d-flex justify-content-between mt-1" v-tooltip="'Your Guard.gg balance was automatically applied to the subtotal.'">
                <div class=" roboto-10">Balance Applied
                  <div class="badge my-auto ml-2">
                    <img src="@/assets/info.svg" alt />
                  </div>
                </div>
                <div class=" roboto-10">-${{toFixed(balanceApplied, 2)}}</div>
              </div>
              
              <div v-if="amountDue > 0">  
                <div class="d-flex justify-content-between ">
                  <div class=" roboto-10 ">Amount Due</div>
                  <!-- <div class=" roboto-10">${{toFixed(amountDue, 2)}}</div> -->
                  <div class=" roboto-10 border-top">${{toFixed(amountDue, 2) }}</div>
                </div>
                <div class="d-flex justify-content-between" v-tooltip="'$0.30 USD For transactions under $50'">
                  <div class=" roboto-10 ">Processor Fee
                    <div class="badge my-auto ml-2">
                      <img src="@/assets/info.svg" alt />
                    </div>
                  </div>
                  <div class=" roboto-10 border-bottom">${{toFixed(processorFee, 2)}}</div>
                </div>
              </div>

              <div class="d-flex mt-1" v-if="hasScrapedStickers">
                <div class="my-auto form-check form-check-inline" style="margin-right: 50px;">
                  <label class="checkmark-container" style="width:0px; font-size: 14px; height: 20px; margin-right: 16px; margin-bottom: 16px;">
                    <input type="checkbox" v-model="scrapedOk" @click="toggleScrapedStickers"/>
                    <span class="checkmark" style="border: 1px solid orange;"></span>
                  </label>

                  <a
                    href="#"
                    class=""
                    @click="toggleScrapedStickers"
                    style="margin-left: 5px;"
                    
                  ><div class="roboto-10" style="margin-bottom: 10px; width: 250px; font-size: 10px;">Confirm cart has item(s) with scratched stickers</div></a>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-3 pl-4 pr-4 pb-3" style="background-color: #F1F1F1">
              <div class="d-flex justify-content-between">
                <div class=" roboto-10" style="font-size:14px; color: #545454">Order total:</div>
                <div class=" roboto-10 cash" style="font-size:14px">${{toFixed(parseFloat(orderTotal), 2)}}</div>
              </div>
            </div>
          <div class="card-footer" style="border: 0">
            <!-- <button v-if="isLoading" disabled class="btn btn-block btn-orange text-uppercase text-center p-3" style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 12px;letter-spacing: 1.5px;text-transform: uppercase;"><span class="loader-nice"></span>Loading</button> -->
            <div v-if="isLoading" class=" btn btn-orange btn-block d-flex">
              <div class="spinner-border text-white" role="status">
              </div>
                <div class="my-auto ml-4 text-white">Loading...</div>
            </div>
            <div v-else >
              <div class=" roboto-10 mb-2 ">By clicking below, you accept Guard.gg's <a href="/terms.pdf" target="_blank">terms of service.</a></div>
              <button @click="pay" class="btn btn-block btn-orange text-uppercase text-center p-3" style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 12px;letter-spacing: 1.5px;text-transform: uppercase;">Confirm {{ amountDue > 0 ? 'and pay' : ''}}</button>

              <div class="d-flex justify-content-center mt-2" v-if="amountDue > 0">
                <div class=""><img class="mx-1" :src="require('@/assets/amex-dark-large.svg')" alt="" srcset=""></div>
                <div class=""><img class="mx-1"  :src="require('@/assets/discover-dark-large.svg')" alt="" srcset=""></div>
                <div class=""><img class="mx-1"  :src="require('@/assets/visa-dark-large.svg')" alt="" srcset=""></div>
                <div class=""><img class="mx-1"  :src="require('@/assets/mastercard-dark-large.svg')" alt="" srcset=""></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
// Swal = Swal.mixin({ heightAuto: false, })
import api from "../api";
import * as mock from '@/mock.js';
import numeral from 'numeral';
import parseItems from "../utils/parseItems";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sortColumn: "quantity",
      sortDirection: "asc",
      isLoading: false,
      scrapedOk: false,
    }
  },
  components: {

  },
  async mounted() {
    // Refresh cart
    await this.$store.dispatch("cart/updateCart");
  },
  computed: {
    orderTotal() {
      if (this.amountDue > 0) return this.amountDue + this.processorFee;
      return this.subTotal; // Or
    },
    processorFee() {
      if (this.amountDue < 50) return 0.30; 
      
      return 0;
    },
    subTotal() {
      return this.cartTotal + this.regionTax;
    },
    amountDue() {
      return Math.max(0, this.subTotal - this.balanceApplied)
    },
    balanceApplied() {
      return Math.min(this.user.balance / 100, this.subTotal)
    },
    // ...mapGetters([]),
    // cartCount() {
    //   return this.games.reduce(function(total, game) { return total + game.items.length }, 0);
    // },
    hasScrapedStickers() {
      return this.$store.getters['cart/items'].find(x => x.stickers && x.stickers.find(sticker => sticker.wear && sticker.wear > 0));
    },
    taxTooltip() {
      if (this.regionCode == "CT") {
        return 'We are required to collect 6.35% sales tax from Connecticut residents.'
      }

      return "";
    },
    regionCode() {
      if (this.user && this.user.lastLoginInfo && this.user.lastLoginInfo.region_code) {
        return this.user.lastLoginInfo.region_code
      }
      return null;
    },
    regionTax() {
      let taxPercentage = 0.00;
      if (this.regionCode == "CT") taxPercentage = 0.0635;

      let taxAmount = this.cartTotal * taxPercentage;
      // taxAmount *= 100;
      // taxAmount = Math.ceil(taxAmount);
      // taxAmount /= 100;

      return taxAmount;
    },
    games() {
      let itemsByGameId = this.$store.getters["cart/cartByGameCondensed"];
      
      let games = [ 
        { id: 730, name: 'CS:GO', items: itemsByGameId[730] || [] }, 
        { id: 440, name: 'TF2', items: itemsByGameId[440] || [] },
        { id: 570, name: "Dota 2", items: itemsByGameId[570] || [] },
        { id: 252490, name: "Rust", items: itemsByGameId[252490] || [] },
        // { id: 433850, name: "Z1:BR", items: itemsByGameId[433850] || [] }
      ];

      return games.filter( game => game.items.length > 0 )
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    cartTotal() {
      return this.$store.getters['cart/total']
    },
    cartCount() {
      return this.$store.getters['cart/count'];
    },
    sortedGames() {
      return this.games.sort(function(gameA, gameB) {
        // TODO: items and decide how it should do the games
      });
    },
    sort() {
      return this.sortColumn + '-' + this.sortDirection;
    }
  },
  methods: {
    toFixed(num, precision) {
      return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    },
    toggleScrapedStickers() {
      this.scrapedOk = !this.scrapedOk;
    },
    parseStickerScrape(wear){
      if(wear === null){
        return "";
      }

      wear = Math.ceil(wear*10)*10;

      return " (" + wear + "% scraped)";
    },
    numeral,
    setQuantity(event) {
      return true;
      Swal.fire("In Progress", "Sorry this function is currently in progress", "info");
    },
    quantityDecrease(item) {
      console.log("quantity decrease");
      return true;
      if (item.type != "COMMODITY") return;
      if (item.qty <= 1) return;
      
      this.$store.commit('cart/removeItemById', item.id);

      this.$forceUpdate();
    },
    async quantityIncrease(item) {
      return true;
      console.log("quantity increase");
      if (item.type != "COMMODITY") return;

      //TODO: Get an available listed item and add to cart
      let commodityItemIds = (await api.get(`/CommodityStats`, { params: { market_hash_name: item.market_hash_name }})).data.items;
      
      let commoditesNotMine   = commodityItemIds.filter(x => x.userId != this.user.id);
      let commoditesNotInCart = commoditesNotMine.filter(x => !this.$store.getters["cart/inCart"]({ id: x.id }));

      console.log(commoditesNotInCart);
      let newItems = await parseItems((await api.getItems({
        ids: commoditesNotInCart[0].id,
        market_hash_name: item.market_hash_name,
        maxItems: 1,
        excludeOwned: true,
      })).data);

      if (!newItems || newItems.length == 0) return console.log(`No new items`);
      console.log("Got item, dispatching");
      console.log(newItems[0])
      this.$store.dispatch('cart/addItem', newItems[0])
      this.$forceUpdate();
    },
    toggleSort(column) {
      if (column == this.sortColumn) {
        if (this.sortDirection == 'asc') {
          this.sortDirection = 'desc'
          return;
        }
        if (this.sortDirection == 'desc') {
          this.sortDirection = 'asc'
          return;
        }
      } else {
        this.sortDirection = 'desc';
        this.sortColumn = column;
        return;
      }
    },
    sortGameItems(game) {
      return game.items.sort(function (itemA, itemB) {

      })
    },
    async remove(item) {
      await this.$store.dispatch('cart/removeItem', item);
    },
    async pay() {
      console.log(`User clicked pay`)
      // Send the order to purchase whats in the cart
      if (!this.user)
        return this.$router.push('/login');

      try {
        let count = parseInt(this.cartCount);

        if (count == 0) return Swal.fire({
          icon: 'error',
          title: "No items!",
          text: "Please add some items to your cart first!"
        });

        if (this.amountDue < 0.50 && this.subTotal > (this.user.balance / 100)) {
          let { dismiss } = await Swal.fire({
                title: "Minimum $0.50",
                text: `To pay via card, you must have an amount due greater than $0.50`,
                showCancelButton: false,
                showCloseButton: true,
                showConfirmButton: false,
            });
            return
        }

        this.isLoading = true;
        // let hasScrapedStickers = this.$store.getters['cart/items'].find(x => x.stickers && c.stickers.find(sticker => sticker.wear && sticker.wear > 0))
        if (this.hasScrapedStickers && !this.scrapedOk) {
          this.isLoading = false
          let { dismiss } = await Swal.fire({
                title: "Scraped stickers",
                text: `Please click accept the accept the scratched sticker checkbox before purchase, or remove items with scratched stickers from your cart.`,
                showCancelButton: true,
                showCloseButton: true,
                showConfirmButton: false,
            });
            
            if (dismiss) return console.log(`Dismissed`);
            return; // dont continue
        }
        
        let response = await this.$store.dispatch('cart/purchaseItems');
        Swal.close();
        console.log("Hello")
        let checkoutUrl = response.data.checkoutUrl;
        if (checkoutUrl) {
          window.location = checkoutUrl;
        }
        let orderId =  response.data.orderId;
        this.isLoading = false;
        // debugger;

        if (orderId) {
          // debugger;
          console.log(orderId);
          let user = this.$store.getters["auth/user"];
          this.$store.dispatch("auth/refreshUser", user);
          // return;
          this.$router.push({ path: `/confirmed/${orderId}` });
          this.$router.push()
        }

      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          return Swal.fire('Error', e.response.data.message, 'warning', { heightAuto: false,})
        }
        if (e && e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0) {
          let errorMessage = e.response.data.errors[0];
          console.error(errorMessage)
          Swal.fire('Error', e.response.data.errors[0], 'warning', { heightAuto: false,})
        } else {
          // Issue with us: "Error: "
          Swal.fire('Error', `It's not you, it's us! We are working on fixing this issue as soon as possible. If the problem persists please contact support`, 'warning', { heightAuto: false,})
        }

        this.isLoading = false;
        console.error("Purchase Error");
        console.error(e)
        // TODO: Handle following errors:
        // - User alreay bought item
        // - Item is locked
        // - Item changed price
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/brand.scss';
@import '@/scss/item.scss';
  .thead {
    font-family: Rubik;
    background-color: #EAEAEA;
    color: #333637;
  }

  .ib{
    vertical-align: middle;
    text-overflow: ellipsis;

    font-size: 14px;
  }

  .td {
    // display: block;
  }

  .item-image {
    padding: 20px 0;
    display: block;
    margin: auto auto;
  }

  .item-image img {
    height: 70px;
  }

  .item-name {
    min-width: 110px;
  }

  .item-remove {
    text-align: center;
  }
  
  .roboto-10 {
    font-family: Roboto;font-style: normal;font-weight: bold;font-size: 10px;color: #979797;
  }
  .cash {
    color: #7BAA64;
  }

  .disabled {
    span,input{
      background-color:#bcb9b9 !important;
      cursor: default !important;
    }

  }
  .qtyinput{
    color:black; 
    padding-left: 2px; 
    padding-right:2px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #222222; 
    border-top:1px solid #E8E8E8;
    border-bottom:1px solid #E8E8E8;
    border-radius: 0 !important; 
     border-left: 0!important; 
     border-right: 0!important;
  }

.sticker-wear-alert-icon-cart {
  position: relative;
  bottom: 10px;
  left: -14px;
}
</style>